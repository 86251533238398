import React, {Component, Fragment, useEffect, useState} from 'react';
import PropTypes from "react-proptypes";
import config from '../../../data/SiteConfig';
import cx from "classnames";
import AnimatedNumber from 'react-animated-number';
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";
import { trackConversion } from "../../utils/Analytics";
import { validateNotEmpty, validatePersonNumber, validatePhone, validateEmail, getUrlParameter } from "../../utils";
import { countries, badCountries } from "../../consts";
import Preload from "../../img/preload.png";
import IconClose from "../../img/icon-close.svg";
import IconCloseWhite from "../../img/icon-close-white.svg";
import IconArrowDown from "../../img/icon-arrow-down.svg";
import IconCheck from "../../img/icon-check.svg";
import { withPrefix, Link } from 'gatsby';

const TRANSITION_TIME = 800;

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer, PaymentElement, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(config.stripePk);

const appearance = {
  theme: 'stripe',
  labels: 'floating',
  variables: {
    fontFamily: 'Montserrat, sans-serif',
    fontSizeBase: '16px',
  }
};
const options = {
  layout: {
    type: 'tabs',
    defaultCollapsed: false,
  }
};

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,

      // "PUT", "years", "marriage", "citizenship", "crime", "debts", "age", "applicantAge", "spouseId", "denied",
      // "order", "success"
      order: true,
      panel: "PUT", // PUT
      progress: 5,
      progressDelayed: 5,
      progressStep: 25,

      livingYears3To4: false,
      tempCitizenship: 0,

      validationShown: false,
      tempFirstName: "",
      tempLastName: "",
      tempPersonNumber: "",
      tempPhoneNumber: "",
      tempEmail: "",
      tempConfirm: null,
      tempConfirm2: null,
      paymentType: 'card',
      paymentError: false,

      orderWaiting: false,
      successAfterOrder: false,
      lastOrderDate: null,

      answersHistory: [],
    };

    this.orderForm = React.createRef();
    this.delayCounter = null;
    this.stripe = undefined;
    this.elements = undefined;
  }

  saveAnswerToHistory = ({ question, answer }) => {
    this.setState(prevState => ({
      answersHistory: [
        ...prevState.answersHistory,
        {
          question,
          answer
        },
      ],
    }));
  };

  resetAllInputs = () => {
    this.setState({
      livingYears3To4: false,
      tempCitizenship: 0,
      validationShown: false,
      tempFirstName: "",
      tempLastName: "",
      tempPersonNumber: "",
      tempPhoneNumber: "",
      tempEmail: "",
      tempConfirm: false,
      tempConfirm2: false,
      order: true,
      paymentError: false
    });
  };
  // Reset to initial state
  resetModal = () => {
    this.resetAllInputs();
    this.setState({
      panel: "PUT",
      progress: 5,
      progressStep: 25,
      order: true,
      successAfterOrder: false,
      paymentError: false,
      payment: undefined,
    });

    this.stripe = undefined;
    this.elements = undefined;
  };

  switchPanel = panel => {
    if (this.state.panel === "order") {
      if (panel === "PUT") {
        this.resetModal();
      } else if (panel === "success") {
        this.setState({
          panel: "success",
          progress: 100,
          successAfterOrder: true,
        });
      }
    } else {
      this.setState(prevState => {
        let progressStep = prevState.progressStep;
        let progress = prevState.progress;

        const badCountry = badCountries.indexOf(prevState.tempCitizenship) > -1;

        if (prevState.progressStep > 10) {
          if (prevState.panel === "years" && panel !== "crime") {
            progressStep -= 10;
          } else if (prevState.panel === "marriage") {
            progressStep -= 5;
          } else if ((prevState.panel === "citizenship") && badCountry) {
            progressStep -= 5;
          }
        }

        if (panel === "denied" || panel === "order") {
          progress = 100;
        } else if (panel === "PUT") {
          progress = 5;
          progressStep = 25;
        } else {
          progress += progressStep;
        }

        return ({panel, progress, progressStep});
      });
    }
  };

  toggleValidation = (onOff) => {
    this.setState({ validationShown: onOff, paymentError: false })
  };

  handleChangeTempValue = (name) => {
    return (event) => {
      let value;

      if (event.target.type !== 'checkbox') {
        value = event.target.value;
      } else {
        value = event.target.checked;
      }

      this.setState({ [name]: value  });
    }
  };

  // Smooth scrolling to element in modal
  scrollToElement = (element, duration, callback) => {
    // If time is over then scrolling has been completed
    if (duration <= 0) {
      callback();
      return;
    }

    // If element is already visible then no need in scrolling
    const offset = 10;
    const elementTopInViewport = element.getBoundingClientRect().top;
    if (elementTopInViewport >= offset) {
      callback();
      return;
    }

    const tickTime = 10;
    const tickScroll = (elementTopInViewport - offset) / duration * tickTime;

    if (this.props.pageOrder) {
      // In order page scroll is implemented in Body
      window.scrollTo(0, window.scrollY + tickScroll);
    } else {
      // In modal view scroll is implemented in Modal
      const modal = document.getElementsByClassName("modal")[0];
      modal.scrollTop = modal.scrollTop + tickScroll;
    }

    setTimeout(
      () => this.scrollToElement(element, duration - tickTime, callback),
      tickTime
    );
  };

  focusFirstError = () => {
    const firstErrorInput = document.getElementsByClassName("modal__input--error")[0];
    if (firstErrorInput) {
      this.scrollToElement(firstErrorInput, TRANSITION_TIME / 2, () => firstErrorInput.focus({preventScroll: true}));
    }
  };

  checkLastOrder = () => {
    const lastOrderDate = Number(localStorage.getItem("lastOrderDate"));
    if (lastOrderDate) {
      this.setState({
        lastOrderDate,
        panel: "success",
        progress: 100,
        progressDelayed: 100,
      });
    }
  };

  componentDidMount() {
    this.setState({ display: true });

    const isSaveParam = (name) => {
      const param = getUrlParameter(name);
      return param && (localStorage.getItem("svenskmedborgare:" + name) !== param);
    };

    ["gclid", "utm_source", "utm_medium", "utm_campaign", "utm_adgroup", "utm_terms"].forEach(tag => {
      // if (getUrlParameter(tag)) {
        localStorage.setItem("svenskmedborgare:" + tag, getUrlParameter(tag));
      // }
    });

    this.checkLastOrder();

    document.addEventListener('click', (event) => {
      if (event.target.matches('.show-survey')) {
        event.preventDefault();
        this.props.toggleModal(true);
      }
    }, false);

    this.stripe = undefined;
    this.elements = undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.progress !== this.state.progress) {
      this.delayCounter = setTimeout(() => this.setState({ progressDelayed: this.state.progress }), TRANSITION_TIME);
    }

    // Reset modal to initial state on modal hide
    if (prevProps.shown && !this.props.shown) {
      this.resetModal();
      this.checkLastOrder();
    } else if ( // Focus the first error input on form submit (filter out updates on inputs type)
      this.state.validationShown &&
      this.state.tempFirstName === prevState.tempFirstName &&
      this.state.tempLastName === prevState.tempLastName &&
      this.state.tempPersonNumber === prevState.tempPersonNumber &&
      this.state.tempPhoneNumber === prevState.tempPhoneNumber &&
      this.state.tempEmail === prevState.tempEmail &&
      this.state.tempConfirm === prevState.tempConfirm &&
      this.state.tempConfirm2 === prevState.tempConfirm2
    ) {
      this.focusFirstError();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayCounter);
    this.stripe = undefined;
    this.elements = undefined;
  }

  fetchPayment() {
    this.stripe = undefined;
    this.elements = undefined;

    if (this.state.payment) {
      this.setState({ order: false, orderWaiting: true });
      this.tracking(this.state.payment.id);
      return;
    }

    const secret = localStorage.getItem("paymentSecret");

    if (secret) {
      const id = localStorage.getItem("paymentId");
      this.setState({
        payment: { secret, id },
        order: false,
        orderWaiting: true
      });
      this.tracking(id);
    } else {
      this.setState({ orderWaiting: true });

      fetch(config.stripeUrl, {
        method: "POST",
        body: {}
      })
        .then(response => response.json())
        .then(({ data }) => {
          this.setState({
            payment: { secret: data.client_secret, id: data.id },
            order: false,
          });
          localStorage.setItem("paymentSecret", data.client_secret);
          localStorage.setItem("paymentId", data.id);
          this.tracking(data.id);
        });
    }
  }

  tracking(id) {
    window.LogRocket && window.LogRocket.identify(id, {
      email: this.state.tempEmail,
      name: this.state.tempFirstName + " " + this.state.tempLastName,
      phone: this.state.tempPhoneNumber,
      payment: id
    });
  }

  render() {
    const { shown, toggleModal, pageOrder, prevUrl } = this.props;
    const {
      panel, progress, progressDelayed, livingYears3To4, tempCitizenship, validationShown, tempFirstName, tempLastName,
      tempPersonNumber, tempPhoneNumber, tempEmail, tempConfirm, tempConfirm2, orderWaiting, successAfterOrder, lastOrderDate,
      answersHistory,  display,
    } = this.state;

    // Before displaying actual data we need to check localstorage for last order so the 1st render should display nothing
    if (!display) {
      return null;
    }

    const msFromLastOrder = lastOrderDate && (new Date().getTime() - lastOrderDate);
    const minsFromLastOrder = Math.floor(msFromLastOrder / 1000 / 60);
    const hoursFromLastOrder = Math.floor(msFromLastOrder / 1000 / 60 / 60);
    const daysFromLastOrder = Math.floor(msFromLastOrder / 1000 / 60 / 60 / 24);

    let timeFromLastOrder = "Du gjorde din beställning ";
    if (daysFromLastOrder > 0) {
      timeFromLastOrder += new Date(lastOrderDate).getDate() + " " +
        ["Jan", "Febr", "Mars", "April", "Maj", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dec"][new Date(lastOrderDate).getMonth()];
    } else if (hoursFromLastOrder > 0) {
      timeFromLastOrder += `${hoursFromLastOrder} timmar sedan`;
    } else if (minsFromLastOrder > 0) {
      timeFromLastOrder += `${minsFromLastOrder} minuter sedan`;
    } else {
      timeFromLastOrder = "Du gjorde din beställning för ett par sekunder sedan";
    }

    const inputsData = [
      {
        value: tempFirstName,
        validate: validateNotEmpty,
        placeholder: "Förnamn",
        onChange: this.handleChangeTempValue("tempFirstName"),
        narrow: true,
      },
      {
        value: tempLastName,
        validate: validateNotEmpty,
        placeholder: "Efternamn",
        onChange: this.handleChangeTempValue("tempLastName"),
        narrow: true,
      },
      {
        value: tempPersonNumber,
        validate: validatePersonNumber,
        placeholder: "ÅÅÅÅMMDD-XXXX",
        onChange: this.handleChangeTempValue("tempPersonNumber"),
        formatError: "Vänligen ange ditt personnummer i följande format ÅÅÅÅMMDD-XXXX. Till exempel, " +
        "19850101-1234. Om du inte kan det fullständiga personnumret, ange endast födelsedatum ÅÅÅÅMMDD.",
      },
      {
        value: tempPhoneNumber,
        validate: validatePhone,
        placeholder: "Telefonnummer",
        onChange: this.handleChangeTempValue("tempPhoneNumber"),
        formatError: "Vänligen ange en giltig telefonnummer.",
      },
      {
        value: tempEmail,
        validate: validateEmail,
        placeholder: "Email adress",
        onChange: this.handleChangeTempValue("tempEmail"),
        formatError: "Vänligen ange en giltig emailadress.",
      },
      {
        name: 'acceptTermsOfUse',
        value: tempConfirm,
        validate: (v) => !!v,
        onChange: this.handleChangeTempValue("tempConfirm"),
      },
      {
        name: 'acceptTermsOfUse2',
        value: tempConfirm2,
        validate: (v) => !!v,
        onChange: this.handleChangeTempValue("tempConfirm2"),
      },
      // {
      //   name: 'paymentType',
      //   value: paymentType,
      //   validate: (v) => !!v,
      //   onChange: this.handleChangeTempValue("paymentType"),
      // }
    ];

    const orderFormSubmit = (event) => {
      event.preventDefault();

      const allValid = inputsData.filter((i => ['acceptTermsOfUse', 'acceptTermsOfUse2'].indexOf(i.name) === -1 )).map(({ value, validate }) => validate(value)).every(valid => valid);

      if (allValid) {
        this.toggleValidation(false);

        this.fetchPayment();
      } else {
        this.toggleValidation(true);
      }
    };

    const paymentFormSubmit = (event) => {
      event.preventDefault();

      const allValid = inputsData.map(({ value, validate }) => validate(value)).every(valid => valid);

      if (allValid) {
        this.toggleValidation(false);
        this.setState({ orderWaiting: true });

        const response = this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                name: (tempFirstName + " " + tempLastName).trim(),
                email: tempEmail.trim(),
                phone: tempPhoneNumber.trim(),
              }
            }
          },
        });

        const orderDataInfo = new FormData();

        const order = () => {
          localStorage.removeItem("paymentSecret");
          localStorage.removeItem("paymentId");

          // Order request
          fetch(config.orderUrl, {
            method: "POST",
            body: orderDataInfo,
          }).then(response => response.text()).then(responseText => {
            localStorage.setItem("lastOrderId", responseText);
            localStorage.setItem("lastOrderDate", new Date().getTime());

            return responseText;
          }).then(orderToken => {
            return trackConversion(orderToken);
          }).then(() => {
            this.setState({ orderWaiting: false });
            this.switchPanel("success");
          });
        }

        response.then((result) => {
          if (result.error) {
            this.setState({ orderWaiting: false});
            this.toggleValidation(true);
            this.setState({ paymentError: true });
          } else {
            // Add user info
            orderDataInfo.append("person[0][first_name]", tempFirstName);
            orderDataInfo.append("person[0][last_name]", tempLastName);
            orderDataInfo.append("person[0][ssn]", tempPersonNumber);
            orderDataInfo.append("person[0][phone]", tempPhoneNumber);
            orderDataInfo.append("person[0][email]", tempEmail);

            // Add answers history
            orderDataInfo.append("person[0][survey]", 1);
            answersHistory.forEach(({ question, answer }) => {
              orderDataInfo.append(`person[0][${question}]`, answer)
            });

            orderDataInfo.append("person[0][utm_source]", this.state.payment.id);
            // // Add URL parameters
            // const utmSource = localStorage.getItem("svenskmedborgare:utm_source");
            // const gclid =  localStorage.getItem("svenskmedborgare:gclid");
            //
            // if ( utmSource ) {
            //   orderDataInfo.append("person[0][utm_source]", utmSource);
            //   orderDataInfo.append("person[0][utm_medium]", localStorage.getItem("svenskmedborgare:utm_medium"));
            //   orderDataInfo.append("person[0][utm_campaign]", localStorage.getItem("svenskmedborgare:utm_campaign"));
            //   orderDataInfo.append("person[0][utm_adgroup]", localStorage.getItem("svenskmedborgare:utm_adgroup"));
            //   orderDataInfo.append("person[0][utm_terms]", localStorage.getItem("svenskmedborgare:utm_terms"));
            //   orderDataInfo.append("person[0][gclid]", localStorage.getItem("svenskmedborgare:gclid"));
            // } else if ( gclid ) {
            //   orderDataInfo.append("person[0][utm_source]", "adwords");
            //   orderDataInfo.append("person[0][utm_medium]", "cpc");
            //   orderDataInfo.append("person[0][gclid]", gclid);
            // }

            order();
          }
        }).catch(error => {
          if (typeof config === 'undefined') {
            var config = {};
          }

          let errMsg = JSON.stringify(config);

          if (!config.orderUrl) {
            errMsg = JSON.stringify(config);
            config.orderUrl = 'https://svenskmedborgare.se/order.php';
          }

          orderDataInfo.append("person[0][first_name]", tempFirstName);
          orderDataInfo.append("person[0][last_name]", tempLastName);
          orderDataInfo.append("person[0][ssn]", tempPersonNumber);
          orderDataInfo.append("person[0][phone]", tempPhoneNumber);
          orderDataInfo.append("person[0][email]", tempEmail);
          orderDataInfo.append("person[0][utm_medium]", errMsg + ' ' + JSON.stringify(error));

          order();
        });
      } else {
        this.toggleValidation(true);
      }
    };

    const panelsData = [
      {
        id: "PUT",
        content: (
          <Fragment>
            <p className="modal__panel_title">Har du Permanent Uppehållstillstånd (PUT)?</p>
            <p className="modal__text">
              Permanent uppehållstillstånd (PUT) innebär att icke svenska medborgare har rätt att bo och arbeta i
              Sverige utan tidsbegränsning. Du måste i regel vara folkbokförd i Sverige utan längre avbrott.
            </p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Ja" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_residency", answer: "Yes" });
                  this.switchPanel("years");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Nej" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_residency", answer: "No" });
                  this.switchPanel("denied");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "years",
        content: (
          <Fragment>
            <p className="modal__panel_title">Hur många år har du bott i Sverige?</p>
            <p className="modal__text">Tiden avser endast oavbruten bosättning med PUT.</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="2 år eller mindre" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_living", answer: "2" });
                  this.switchPanel("denied");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="3-4 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_living", answer: "3" });
                  this.switchPanel("applicantAge");
                  this.setState({ livingYears3To4: true });
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="5-7 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_living", answer: "5" });
                  this.switchPanel("citizenship");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="8 år eller mer" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_living", answer: "8" });
                  this.switchPanel("crime");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "marriage",
        content: (
          <Fragment>
            <p className="modal__panel_title">Är du gift eller sambo med en svensk medborgare?</p>
            <p className="modal__text">Ni måste ha bott tillsammans i minst 3 år.</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Ja" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_marriage", answer: "Yes"});
                  this.switchPanel("citizenship");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Nej" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_marriage", answer: "No"});
                  this.switchPanel("denied")
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "citizenship",
        content: (
          <Fragment>
            <p className="modal__panel_title">Vilket land är du medborgare i?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <div className="modal__select_wrapper">
                  <select className="modal__select" value={tempCitizenship} onChange={this.handleChangeTempValue("tempCitizenship")}>
                    <option value="0">Välj nedan</option>
                    <option value="1">Statslös</option>
                    {countries.map(country => (
                      <option value={country} key={country}>{country}</option>
                    ))}
                  </select>

                  <img className="modal__select_arrow" src={IconArrowDown} alt="" />
                </div>
              </div>
              <div className="modal__action_wrapper">
                <Button
                  shape="flatLighten"
                  content="Fortsätt"
                  onClick={() => {
                    this.saveAnswerToHistory({ question: "q_citizenship", answer: tempCitizenship });
                    const badCountry = badCountries.indexOf(tempCitizenship) > -1;
                    this.switchPanel(badCountry ? (livingYears3To4 ? "denied" : "crime") : "crime");
                  }}
                />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "crime",
        content: (
          <Fragment>
            <p className="modal__panel_title">Har du blivit dömd för ett brott i Sverige det senaste året?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Ja" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_crime", answer: "Yes" });
                  this.switchPanel("denied");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Nej" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_crime", answer: "No" });
                  this.switchPanel("debts");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "debts",
        content: (
          <Fragment>
            <p className="modal__panel_title">Har du skulder hos Kronofogden?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Ja" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_debts", answer: "Yes" });
                  this.switchPanel("denied");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Nej" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_debts", answer: "No" });
                  this.switchPanel("order");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "age",
        content: (
          <Fragment>
            <p className="modal__panel_title">Har du skulder hos Kronofogden?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Mellan 18-21 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_age", answer: "18"});
                  this.switchPanel("crime");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Över 21 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_age", answer: "21"});
                  this.switchPanel("spouseId");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "applicantAge",
        content: (
          <Fragment>
            <p className="modal__panel_title">Hur gammal är du eller den sökande?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Under 18 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_applicant_age", answer: "under_18" });
                  this.switchPanel("order");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Över 18 år" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_applicant_age", answer: "over_18" });
                  this.switchPanel("marriage");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "spouseId",
        content: (
          <Fragment>
            <p className="modal__panel_title">Har du skulder hos Kronofogden?</p>

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Ja" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_id", answer: "Yes" });
                  this.switchPanel("crime");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Svensk medborgare sedan födseln" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_id", answer: "Yes" });
                  this.switchPanel("crime");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Nej" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_id", answer: "Yes" });
                  this.switchPanel("denied");
                }} />
              </div>
              <div className="modal__action_wrapper">
                <Button shape="flatLighten" content="Jag är inte sambo eller gift med en svensk medborgare" onClick={() => {
                  this.saveAnswerToHistory({ question: "q_id", answer: "Yes" });
                  this.switchPanel("denied");
                }} />
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        id: "denied",
        content: (
          <Fragment>
            <p className="modal__panel_title">Tyvärr så uppfyller du inte kraven</p>
            <p className="modal__text">
              Detta innebär inte att du inte kan söka i framtiden. Du är alltid välkommen att kontakta oss om du har några frågor.
            </p>
            <p className="modal__text">
              Detta test ger bara ett förenklat resultat och är bara en generell uppskattning av dina chanser att bli svensk medborgare.
            </p>
          </Fragment>
        ),
      },
      {
        id: "order",
        content: this.state.order ? (
          <form ref={this.orderForm} className="modal__form" onSubmit={orderFormSubmit}>
            <p className="modal__panel_title">Steg 1 av 2:</p>
            <p className="modal__text modal__text--strong">
              Fyll i dina person och kontaktuppgifter.
            </p>
            <p className="modal__text">
              Vi kontaktar dig för boka ett personligt telefonsamtal.
            </p>

            <div className="modal__actions">
              {inputsData.filter(i => ['acceptTermsOfUse', 'acceptTermsOfUse2', 'paymentType %hidden%'].indexOf(i.name) === -1).map(({ value, validate, placeholder, onChange, narrow, formatError }, index) => {
                const error = validationShown && !validate(value);
                const ok = validationShown && validate(value);

                return (
                  <div
                    key={index}
                    className={cx(
                      "modal__action_wrapper",
                      { "modal__action_wrapper--narrow": narrow },
                      { "modal__action_wrapper--wide": !narrow }
                    )}
                  >
                    <input
                      className={cx("modal__input", { "modal__input--error": error }, { "modal__input--ok": ok })}
                      value={value}
                      onChange={onChange}
                      placeholder={placeholder}
                    />

                    {error && value !== "" && formatError && <div className="modal__input_error">{formatError}</div>}
                  </div>
                );
              })}
            </div>

            <p className="modal__text">
              Vänligen kontrollera att alla uppgifter stämmer. Läs igenom all information noggrant.
            </p>

            {inputsData.filter(i => i.name === "paymentType %hidden%").map(({ value, validate, placeholder, onChange, narrow, formatError }, index) => {
              const error = validationShown && !validate(value);
              const ok = validationShown && validate(value);

              return (
                <label
                  key={index}
                  className={cx(
                    "modal__text modal__checkbox modal__checkbox--radio",
                    { "modal__checkbox--error modal__input--error": error }
                  )}
                  htmlFor="paymentType1"
                >
                  <input
                    id="paymentType1"
                    type="radio"
                    checked={value === 'card'}
                    value={'card'}
                    onChange={onChange}
                  />

                  <span className="modal__checkbox_mark">
                    {value === 'card' && <img src={IconCheck}/>}
                  </span>

                  <div>
                    <div>
                      Pay by credit card
                    </div>
                  </div>
                </label>
              );
            })}

            {inputsData.filter(i => i.name === "paymentType %hidden%").map(({ value, validate, placeholder, onChange, narrow, formatError }, index) => {
              const error = validationShown && !validate(value);
              const ok = validationShown && validate(value);

              return (
                <label
                  key={index}
                  className={cx(
                    "modal__text modal__checkbox modal__checkbox--radio",
                    { "modal__checkbox--error modal__input--error": error }
                  )}
                  htmlFor="paymentType2"
                >
                  <input
                    id="paymentType2"
                    type="radio"
                    checked={value === 'invoice'}
                    value={'invoice'}
                    onChange={onChange}
                  />

                  <span className="modal__checkbox_mark">
                    {value === 'invoice' && <img src={IconCheck}/>}
                  </span>

                  <div>
                    <div>
                      Pay by invoice
                    </div>
                  </div>
                </label>
              );
            })}

            <div className="modal__actions">
              <div className="modal__shrinked_btn_wrapper">
                <Button shape="raised" content="Nästa" onClick={orderFormSubmit} />
              </div>
            </div>

            {/* Hidden input for form submit event firing */}
            <button style={{ display: "none" }} type="submit" />
          </form>
        ) : (<form ref={this.orderForm} className="modal__form" onSubmit={paymentFormSubmit}>
          <p className="modal__panel_title">
            <span>Steg 2 av 2:</span>
          </p>
          <p className="modal__text modal__text--strong">
            Betala med kort
          </p>
          <p className="modal__text">
            Betala säkert och enkelt med kort. Alla betalningar via kort utförs av en ledande aktör på marknaden som tillhandahåller betalningslösningar online.
          </p>
          <p className="modal__text">
            Svenskmedborgare.se sparar inga kortuppgifter eller annan känslig information.
          </p>

          <div className="modal__actions">
            {this.state.payment ? <Elements options={{
              clientSecret: this.state.payment.secret,
              locale: 'sv',
              appearance,
              options
            }} stripe={stripePromise}>
              <ElementsConsumer>
                {({stripe, elements}) => {
                  if (!this.stripe) {
                    this.stripe = stripe;
                  }

                  if (!this.elements) {
                    this.elements = elements;
                  }

                  return (
                    <PaymentElement
                      onLoaderStart={() => this.setState({ orderWaiting: false })}
                      options={{
                        fields: {
                          billingDetails: {
                            name: "auto",
                            email: "auto",
                            address: "auto",
                            phone: "auto"
                          }
                        },
                        defaultValues: {
                          billingDetails: {
                            name: tempFirstName + " " + tempLastName,
                            email: tempEmail,
                            phone: tempPhoneNumber,
                            address: { country: "SE" }
                          }
                        }
                      }}
                    />
                  )
                }}
              </ElementsConsumer>
            </Elements> : null}

            {this.state.paymentError ?
              <Fragment>
                <div className="modal__input_error">
                  Ditt köp medges ej.
                </div>

                <div className="modal__input_error">
                  Kortutgivaren har av någon anledning inte kunnat godkända den aktuella transaktionen.
                </div>

                <div className="modal__input_error">
                  Vänligen kontrollera dina uppgifter igen eller försök att använda ett annat betalkort.
                </div>
              </Fragment> :
            null}

          </div>

          <div className="modal__price">Pris: 1390 kr.</div>

          <p className="modal__text">
            Migrationsverkets avgifter ingår inte.
          </p>

          {inputsData.filter(i => i.name === 'acceptTermsOfUse').map(({ value, validate, placeholder, onChange, narrow, formatError }, index) => {
            const error = validationShown && !validate(value);
            const ok = validationShown && validate(value);

            return (
              <label
                key={index}
                className={cx(
                  "modal__text modal__checkbox",
                  { "modal__checkbox--error modal__input--error": error }
                )}
                htmlFor="acceptTermsOfUse"
              >
                <input
                  id="acceptTermsOfUse"
                  type="checkbox"
                  checked={value}
                  onChange={onChange}
                />

                <span className="modal__checkbox_mark">
                    {value && <img src={IconCheck}/>}
                  </span>

                <div>
                  <div>
                    Ja, ni får behandla mina personuppgifter och jag godkänner era&nbsp;
                    <a href={withPrefix("/kopvillkor/")} className="modal__link" target="_blank">köpvillkor</a>.
                  </div>
                </div>
              </label>
            );
          })}

          {inputsData.filter(i => i.name === 'acceptTermsOfUse2').map(({ value, validate, placeholder, onChange, narrow, formatError }, index) => {
            const error = validationShown && !validate(value);
            const ok = validationShown && validate(value);

            return (
              <label
                key={index}
                className={cx(
                  "modal__text modal__checkbox",
                  { "modal__checkbox--error modal__input--error": error }
                )}
                htmlFor="acceptTermsOfUse2"
              >
                <input
                  id="acceptTermsOfUse2"
                  type="checkbox"
                  checked={value}
                  onChange={onChange}
                />

                <span className="modal__checkbox_mark">
                    {value && <img src={IconCheck}/>}
                  </span>

                <div>
                  <div>
                    Jag förstår att Svenskmedborgare.se är ett företag och jag är medveten om att ni inte är Migrationsverket, Länsstyrelsen eller någon statlig myndighet.
                  </div>
                </div>
              </label>
            );
          })}

          <div className="modal__actions">
            <div className="modal__shrinked_btn_wrapper">
              <Button shape="raised" content="Beställ" onClick={paymentFormSubmit} />
            </div>
          </div>

          {/* Hidden input for form submit event firing */}
          <button style={{ display: "none" }} type="submit" />
        </form>),
      },
      {
        id: "success",
        content: (
          <Fragment>
            {successAfterOrder ? (
              <Fragment>
                <p className="modal__panel_title">Tack för din beställning!</p>

                <p className="modal__text">
                  Vi kommer att behandla din beställning så fort som möjligt. Du får en bekräftelse samt kopior på din ansökan och faktura via e-mail.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <p className="modal__panel_title">Din beställning har tagits emot</p>

                <p className="modal__text">
                  {timeFromLastOrder}.
                  <br />
                  Om du har några frågor kan du ringa oss på 08-559 23 228.
                </p>
              </Fragment>
            )}

            <div className="modal__actions modal__actions--left">
              <div className="modal__action_wrapper">
                <Button
                  shape="flatLighten"
                  content="Gör en ny beställning"
                  onClick={() => {
                    this.resetModal();
                  }}
                />
              </div>
            </div>
          </Fragment>
        ),
      },
    ];

    const modalShown = shown || pageOrder;

    return (
      <Fragment>
        <div className={cx("modal", { "modal--shown": modalShown }, { "modal--page": pageOrder})}>
          <div className={cx("modal__content", { "modal__content--shown": modalShown })}>
            <div className="modal__top_row">
              {(panel !== "PUT") && (panel !== "success") && (
                <div className="modal__top_row_btn_wrapper">
                  <Button
                    shape="flatBorderedTransparent1"
                    content={<span className="modal__top_row_btn_content">Börja om</span>}
                    onClick={() => {
                      this.saveAnswerToHistory({ question: "q_not_eligible", answer: "Yes" });
                      this.switchPanel("PUT")
                    }}
                  />
                </div>
              )}

              <Link
                to={prevUrl}
                className="modal__close"
                type="button"
                onClick={(event) => {
                  if (!pageOrder) {
                    event.preventDefault();
                  }
                  toggleModal(false);
                }}
              >
                <img className="modal__close_icon modal__close_icon--mobile" src={IconCloseWhite} alt="" />
                <img className="modal__close_icon modal__close_icon--desktop" src={IconClose} alt="" />
              </Link>
            </div>

            <div className="modal__title">
              <h2 className="modal__title_title">Bli svensk medborgare</h2>
              <div className="modal__title_underline" />
            </div>

            <div className={cx("modal__panels")}>
              {panelsData.map(panelData => (
                <div
                  className={cx("modal__panel", { "modal__panel--visible": panelData.id === panel })}
                  key={panelData.id}
                >
                  <div className="modal__badges">
                    <div className="modal__progress" style={{ width: `${progress}%` }} />

                    <div className="modal__badge modal__badge--progress" style={{ left: `${progress}%` }}>
                      <span
                        className={cx(
                          "modal__badge_percent",
                          { "modal__badge_percent--hidden": (panel === "denied" || panel === "order") }
                        )}
                      >
                        <AnimatedNumber
                          initialValue={progress}
                          value={progressDelayed}
                          formatValue={value => Math.round(value)}
                          duration={TRANSITION_TIME}
                        />
                        %
                      </span>

                      <div
                        className={cx(
                          "modal__badge_overlay modal__badge_overlay--order",
                          { "modal__badge_overlay--shown": (panel === "order") },
                        )}
                      />

                      <div
                        className={cx(
                          "modal__badge_overlay modal__badge_overlay--success",
                          { "modal__badge_overlay--shown": (panel === "success") },
                        )}
                      />

                      <div
                        className={cx(
                          "modal__badge_overlay modal__badge_overlay--denied",
                          { "modal__badge_overlay--shown": panel === "denied" },
                        )}
                      >
                        <img className="modal__icon--denied" src={IconCloseWhite} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="modal__smallprogress">
                    <div className="modal__smallprogress_progressbar">
                      <div className="modal__smallprogress_progressbar_progress" style={{ width: `${progress}%`}} />
                    </div>
                    <div className="modal__smallprogress_text">
                      <AnimatedNumber
                        initialValue={progress}
                        value={progressDelayed}
                        formatValue={value => Math.round(value)}
                        duration={TRANSITION_TIME}
                      />
                      % besvarat
                    </div>
                  </div>

                  <div className="modal__panel_content">{panelData.content}</div>
                </div>
              ))}
            </div>

            {(panel !== "PUT") && (panel !== "success") && (
              <div className="modal__footer">
                <p className="modal__footer_text">Fel svar?</p>

                <div className="modal__shrinked_btn_wrapper">
                  <Button
                    shape="flatBorderedTransparent1"
                    content={<span className="modal__footer_btn_content">Börja om</span>}
                    onClick={() => {
                      this.saveAnswerToHistory({ question: "q_not_eligible", answer: "Yes" });
                      this.switchPanel("PUT")
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={cx("modal__preload", { "modal__preload--visible": orderWaiting })}>
          <img className={cx("modal__preload_img")} src={Preload} alt="" />
        </div>
      </Fragment>
    );
  }
}

Modal.propTypes = {
  shown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  pageOrder: PropTypes.bool,
  prevUrl: PropTypes.string,
};

Modal.defaultProps = {
  pageOrder: false,
  prevUrl: "/",
};

function ElementsWrapper({shown, pageOrder, prevUrl, toggleModal}) {
  return (
     <Modal shown={shown} pageOrder={pageOrder} prevUrl={prevUrl} toggleModal={toggleModal} />
  )
}

export default class ModalHOC extends Component {
  render() {
    const { pageOrder } = this.props;
    return (
      <ContextConsumer>
        {({ modalShown, toggleModal, prevUrl }) => (
          <ElementsWrapper shown={modalShown} pageOrder={pageOrder} prevUrl={prevUrl} toggleModal={toggleModal}  />
        )}
      </ContextConsumer>
    );
  }
}

ModalHOC.propTypes = {
  pageOrder: PropTypes.bool,
};

ModalHOC.defaultProps = {
  pageOrder: false,
};
