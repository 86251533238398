const sharedConfig = require("./SharedConfig");

let config = {
  ...sharedConfig,
  siteUrl: "https://svenskmedborgare.se/", // Domain of your website without pathPrefix.
  pathPrefix: "/v1", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  orderUrl: "/order.php",
  stripePk: 'pk_live_51PJLcEF76rmtgYAynWUMKNyIMPIMINK0w5qLZFnng8NeYBnTntgBfHFVboo7hi2yhIfsbhDCVoZeHmG130cDtBSm005MECkzR2', // "pk_live_51OY9BIAW2fZ7DaTygOFKbkUy5gT5rt4Ad5Evqo9GkkEKnSnSSNZ0FVIrEGLgqGgGCJfL0W8oi0vp8HzIMKrdItK400tY5fYxkt", // "pk_test_51GuZMPBHRmk9fvRz8eWAf0gR8qd3T0PhfIbEA7eev1l0tOcpuFqs1JGchLbJo4TmxDAttIBC5aOttltF5BKBmiu400zSQ4Ou3q", // ,
  stripeUrl: "https://8f9oauwho8.execute-api.us-east-1.amazonaws.com/prod/svenskmedborgare-payments", // "https://suxtdz55di.execute-api.us-east-1.amazonaws.com/dev/svenskmedborgare-payments",
};

config.validate();

module.exports = config;
